/** @jsx jsx */
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
import { visuallyHidden } from "@lekoarts/gatsby-theme-minimal-blog/src/styles/utils"
import { jsx } from "theme-ui"
import Typewriter from 'typewriter-effect'
// @ts-ignore
import Hero from "../texts/hero"


type PostsProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
  [key: string]: any
}

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig()
  const { siteTitle } = useSiteMetadata()

  return (
    <Layout>
      <h1 sx={visuallyHidden}>{siteTitle}</h1>
      <section sx={{ mb: [5, 6, 7], p: { fontSize: [1, 2, 3], mt: 2 }, variant: `section_hero` }}>
      <h2 sx={{ fontFamily: "lora", fontSize: [4, 5, 6], fontWeight: `bold`, color: `heading` }}>
        <Typewriter options={{ cursor: '_' }} onInit={(typewriter) => {
            typewriter
                .changeDelay(80)
                .changeDeleteSpeed(50)
                .typeString('Développeur')
                .pauseFor(1000)
                .deleteChars(11)
                .typeString('Software Devel')
                .pauseFor(1000)
                .deleteChars(2)
                .pauseFor(1000)
                .typeString(', ')
                .pauseFor(1000)
                .deleteChars(5)
                .pauseFor(400)
                .typeString('Engineer')
                .pauseFor(600)
                .typeString(', ')
                .pauseFor(600)
                .typeString('Tech Lead')
                .pauseFor(400)
                .typeString(' !')
                .start();
          }}></Typewriter>
          </h2>
        <Hero />
      </section>
    </Layout>
  )
}

export default Homepage
