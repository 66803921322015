import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi! Je m'appelle `}<strong parentName="p">{`Julien`}</strong>{` 👋`}</p>
    <p>{`Je suis dans l'IT depuis plus de 13 ans.
Je suis `}<strong parentName="p">{`indépendant`}</strong>{`, j'interviens dans toute la région de `}<strong parentName="p">{`Lille`}</strong>{` 🍺 ou évidemment en `}<strong parentName="p">{`full remote`}</strong>{` 🌍`}</p>
    <p>{`Je suis passionné par ce que je fais, j'aime `}<strong parentName="p">{`apprendre`}</strong>{` (sans cesse) et partager mes connaissances.
J'aime `}<strong parentName="p">{`construire`}</strong>{` des choses qui apportent de la valeur (aident les autres !) et plus particulièrement grâce au code 👨‍💻🚀`}</p>
    <p>{`Je suis intéressé par toute pratique qui permet de maximiser la `}<strong parentName="p">{`qualité`}</strong>{` logicielle et la `}<strong parentName="p">{`productivité`}</strong>{` des équipes de développement 🥋 `}<strong parentName="p">{`#softwarecraftsmanship`}</strong></p>
    <p>{`Mes joujoux du moment sont principalement : Node.js, NestJS, TypeScript, MongoDB, et bien sûr le cloud ☁️`}</p>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const sweetWords = ["Check", "my", "LinkedIn", "profile"];
const invitation = sweetWords.join(" ").concat("!");

console.log(invitation);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      